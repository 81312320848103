import React from 'react'
import styled, { css } from 'styled-components'

import { BREAKPOINTS } from './shared/grid'
import Spacing from './shared/spacing'
import Theme from './shared/colors'

import { H1, P } from './shared/typography'
import ImageWithSVGSupport from './image-with-svg-support'

import Link from './shared/text-link'

const PostThumb = ({ company, dateText }) => {
    return (
        <Thumb>
            {company && (
                <>
                    {company.logo && (
                        <Logo
                            file={company.logo.file}
                            fluid={company.logo.fluid}
                        />
                    )}
                    {company.companyName && (
                        <P>
                            <Link node={company} inverted={1}>
                                {company.companyName}
                            </Link>
                        </P>
                    )}
                </>
            )}
            {dateText && <P>{dateText}</P>}
        </Thumb>
    )
}

const Thumb = styled.div`
    display: block;
    padding: 0 0 0 ${Spacing.xxxl};
    margin-bottom: 10vw;
    text-align: left;

    @media ${BREAKPOINTS.mobile} {
        text-align: right;
    }
`

const Logo = styled(ImageWithSVGSupport)`
    max-width: 100px;

    @media ${BREAKPOINTS.mobile} {
        margin-left: auto;
    }
`

export default PostThumb
