import React from 'react'
import styled, { css } from 'styled-components'

import { BREAKPOINTS } from './shared/grid'
import Spacing from './shared/spacing'
import Theme from './shared/colors'

import { H1, typestyle } from './shared/typography'
import ImageWithSVGSupport from './image-with-svg-support'

const PageCover = ({ image, text }) => {
    return (
        <Header>
            {image && <Image file={image.file} fluid={image.fluid} cover='cover' />}
            <Heading size={'display'}>{text}</Heading>
        </Header>
    )
}

const Header = styled.header`
    position: relative;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr;
    min-height: 20vh;
    text-align: left;
    color: ${Theme.text.primary};
    overflow: hidden;

    @media ${BREAKPOINTS.mobile} {
        max-height: 80vh;
        min-height: 65vh;
    }
`

const Image = styled(ImageWithSVGSupport)`
    position: relative;
    display: block;
    grid-column: 1 / -1;
    grid-row: 1 / -1;
    z-index: 1;
    margin: 0;
    padding: 0;

    &::after {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        display: block;
        background: linear-gradient(
            90deg,
            ${Theme.ui.primary} 0%,
            rgba(255, 255, 255, 0) 100%
        );
        content: '';

        @media ${BREAKPOINTS.mobile} {
            background: linear-gradient(
                90deg,
                ${Theme.ui.primary} 0%,
                rgba(255, 255, 255, 0) 50%
            );
        }
    }
`

const Heading = styled(H1)`
    grid-column: 1 / -1;
    grid-row: 1 / -1;
    padding: 10vw 10vw;
    align-self: end;
    z-index: 2;

    @media ${BREAKPOINTS.desktop} {
        grid-column: 1 / 2;
        color: ${Theme.text.primary};
    }
`

export default PageCover
