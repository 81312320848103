import React from 'react'
import styled, { css } from 'styled-components'

import { BREAKPOINTS, GRIDS } from './shared/grid'
import Spacing from './shared/spacing'
import Theme from './shared/colors'

import { H2, H3 } from './shared/typography'

const PageTitle = ({ header, subtitle }) => {
    return (
        <Header>
            
            {header && (
                <Heading>
                    <ArrowIcon src={'/arrow-right.svg'} />
                    {header}
                </Heading>
            )}
            {subtitle && (
                <Subtitle size={'h4'}>{subtitle}</Subtitle>
            )}
        </Header>
    )
}

const Header = styled.div`
    display: grid;
    padding: 10vw 0 4vw;
    text-align: left;

    ${() => GRIDS.content()}
`

const Heading = styled(H2)`
    position: relative;
    padding: 0 10vw 0 0;
    grid-column: left-main / end;

    @media ${BREAKPOINTS.mobile} {
        padding: 0;
        grid-column: left-main / right-gutter;
    }
`

const Subtitle = styled(H3)`
    font-weight: normal;
    padding: 0 10vw 0 0;
    grid-column: left-main / end;

    @media ${BREAKPOINTS.mobile} {
        padding: 0;
        grid-column: left-main / right-gutter;
    }
`

const ArrowIcon = styled.img`
    position: absolute;
    left: -1em;
    max-height: 2ex;
    max-width: 2ex;
`

export default PageTitle
