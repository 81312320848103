import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Layout from '../components/layout'
import styled, { css } from 'styled-components'

import { BREAKPOINTS, GRIDS } from '../components/shared/grid'
import Spacing from '../components/shared/spacing'
import Theme from '../components/shared/colors'

import Header from '../components/global-header'
import Footer from '../components/global-footer'
import PrimaryContent from '../components/primary-content'
import PageCover from '../components/page-cover'
import PageTitle from '../components/page-title'
import PostThumb from '../components/post-thumb'
import SEO from '../components/seo'

class StandardPageTemplate extends React.Component {
    render() {
        const page = get(this.props, 'data.contentfulStandardPage')
        const hasLeadImage =
            typeof page.leadImage !== 'undefined' && page.leadImage !== null

        return (
            <Layout location={this.props.location}>
                <SEO title={page.title} description={page.description} />
                <Header />
                <Main>
                    {page.leadImage && (
                        <PageCover
                            image={page.leadImage}
                            text={page.primaryHeading}
                        />
                    )}
                    <PageTitle
                        header={page.secondaryHeading}
                        subtitle={page.subtitleHeading}
                    />
                    {(page.displayType === 'Post' || page.displayType === 'Work') && (
                        <PostContent>
                            <Aside>
                                {page.relatedExpert && (
                                    <PostThumb
                                        company={
                                            page.relatedExpert.relatedCompany
                                        }
                                        dateText={page.formattedDate}
                                    />
                                )}
                            </Aside>
                            <Content data={page.primaryContent} />
                        </PostContent>
                    )}
                    {(page.displayType === 'Page' || page.displayType === 'News' || page.displayType === 'Podcast') && (
                        <PageContent>
                            <Content data={page.primaryContent} />
                        </PageContent>
                    )}
                </Main>
                <Footer />
            </Layout>
        )
    }
}

export default StandardPageTemplate

export const pageQuery = graphql`
    query StandardPageBySlug($slug: String!) {
        site {
            siteMetadata {
                title
            }
        }
        contentfulStandardPage(slug: { eq: $slug }) {
            title
            slug
            displayType
            postedDate(formatString: "M.DD.YYYY")
            formattedDate: postedDate(formatString: "M.DD.YYYY")
            primaryHeading
            secondaryHeading
            subtitleHeading
            leadImage {
                ...Asset
            }
            relatedExpert {
                ...Expert
            }
            primaryContent {
                raw
                references {
                    ...Asset
                    ...BlockContentList
                    ...BlockFormEmbed
                    ...BlockPodcast
                    ...StandardPage
                }
            }
        }
    }
`

const Main = styled.main`
    background-color: ${Theme.ui.inverse};
    color: ${Theme.text.inverse};
`

const PostContent = styled.div`
    display: grid;
    padding: 0 0 10vw;

    ${() => GRIDS.content()}
`

const Aside = styled.aside`
    display: block;
    grid-column: left-gutter / right-gutter;

    @media ${BREAKPOINTS.mobile} {
        grid-column: left-gutter / main;
        height: 0px;
    }
`

const Content = styled(PrimaryContent)`
    grid-column: main / right-gutter;
`

const PageContent = styled.div`
    display: grid;
    padding: 0 0 10vw;

    ${() => GRIDS.content()}
`
